import React, { useRef, useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'
import { el, enGB } from 'date-fns/locale'
// import GeneralIntro from "../components/Global/GeneralIntro"

import Calendar from "./../images/calendar.svg"
import searchSvg from "./../images/search-solid.svg"
import folderIcon from "../images/folder-regular.svg";
import AboutUs from "../components/AboutUs/AboutUs"
import readMoreIcon from "../images/circle-right-regular.svg"
import { GatsbyImage } from "gatsby-plugin-image";

import "./../components/Posts/BlogPageTemplate.css";


import '@wordpress/block-library/build-style/style.css'

function BlogPageTemplate(props) {
    console.log(props)
    let data = props.data;
    // const [search, setSearch] = useState("");
    // const [searchResults, setSearchResults] = useState([]);

    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://ficon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://ficon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            locale={props.pageContext.language}
            metaData={constructMetaData(data.allWpCategory.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <section id="firstSection">
                {/* <GeneralIntro
                        data={data.allWpCategory.nodes[0]}
                    /> */}
                <AboutUs
                    data={data.allWpCategory.nodes[0]}
                    csImage={data.allWpCategory.nodes[0].postCategoryAcf.categoryExtra.image}
                />
            </section>
            <section>
                <div className="container w-full m-auto my-12 md:my-24">
                    <div className="w-full flex flex-wrap">
                        {/* <div className="w-full md:w-1/4 flex flex-wrap order-2 md:order-1 px-4">
                            <div className="w-full">
                                <div className="relative w-full">
                                    <h3 className="side-cat-h3">
                                        {props.pageContext.language === "EL" ? "Αναζήτηση" : "Search"}
                                    </h3>
                                    <div className="flex relative">
                                        <input value={search} onChange={(e) => setSearch(e.target.value)} className="search-i" type="text" />
                                        <button onClick={() => sendSearch(search)} className="search-b">
                                            <img src={searchSvg} width={15} />
                                        </button>
                                    </div>
                                </div>
                                {(searchResults && searchResults.length > 0) && (
                                    <ul className="search-ul">
                                        {searchResults.map((sc, i) => (
                                            <li key={`search-result-${i}`}>
                                                <Link to={`${props.pageContext.language === "EN" ? "/en" : ""}/blog/${sc.node.slug}`}>
                                                    {sc.node.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div> */}

                        <div className="w-full flex flex-wrap items-stretch">
                            {data.allWpPost.nodes.map((program, i) => {
                                return (
                                    <a href={`/blog` + program.uri} key={`program-post-${i}`} className='w-full md:w-1/3 px-4 pb-4 md:pb-8'>
                                        <div
                                            className='prog-wrapper'
                                            style={{
                                                backgroundImage: `url(${program.featuredImage.node.sourceUrl})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <div className='prog-overlay'>
                                                <div>
                                                    {(program?.tags && program.tags?.nodes?.length > 0) && program.tags.nodes.map((tag, j) => (
                                                        <React.Fragment>
                                                            <span className='program-tag' key={`program-tag-${j}`}>{tag.name}</span>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                                <h3 className='program-h3'>{program.title}</h3>
                                                <div className='flex justify-start pt-6'>
                                                    <a href={`/blog` + program.uri}>Διαβάστε περισσότερα</a>
                                                    <a href={`/blog` + program.uri}>
                                                        <img className='read-more-icon' src={readMoreIcon} width="17" style={{ filter: "invert(1)" }} />
                                                    </a>
                                                </div>
                                            </div>

                                            <div className='prog-overlay2'>
                                                <div dangerouslySetInnerHTML={{ __html: program.excerpt }} />
                                            </div>
                                        </div>
                                    </a>
                                );
                            })}
                            {/* <div className="flex justify-center w-full">

                                <ul className="flex justify-center py-8 px-4">
                                    {props.pageContext.currentPage === 2 && (
                                        <li className="mr-4 relative"  >
                                            <Link
                                                to={`${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug}/` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}`}
                                                className={"py-2 px-4 pagination-link bp-arrow"}
                                            >
                                            </Link>
                                        </li>
                                    )}

                                    {props.pageContext.currentPage > 2 && (
                                        <li className="mr-4 relative"  >
                                            <Link
                                                to={`${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug}/` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}${props.pageContext.currentPage - 1}/`}
                                                className={"py-2 px-4 pagination-link bp-arrow"}
                                            >
                                            </Link>
                                        </li>
                                    )}

                                    {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => {
                                        if ((props.pageContext.currentPage + 1 >= index) && (props.pageContext.currentPage - 1 <= index)) {
                                            return (
                                                <li key={index} className={`mr-4 `}  >
                                                    <Link
                                                        to={index === 0 ? `${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug}/` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}` : `${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug}/` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}${index + 1}/`}
                                                        className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
                                                    >
                                                        {index + 1}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    }))}

                                    {props.pageContext.currentPage < props.pageContext.numberOfPages && (
                                        <li className="mr-4 relative"  >
                                            <Link
                                                to={`${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug}/` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}${props.pageContext.currentPage + 1}/`}
                                                className={"py-2 px-4 pagination-link bp-arrow-r"}
                                            >
                                            </Link>
                                        </li>
                                    )}

                                </ul>
                            </div> */}
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BlogPageTemplate

export const pageQuery = graphql`query GET_BLOG_CATEGORY_PAGE($id: String, $templateId: String , $language: WpLanguageCodeEnum, $startPoint: Int, $postsPerPage: Int) {
    allWpCategory(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {
            slug
            name
            description
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
            }

            postCategoryAcf {
                categoryExtra {
                    image{
                        sourceUrl
                        altText
                    }
                }
            }
        }
    }

    allWpPost(skip: $startPoint, limit: $postsPerPage, sort: { fields: [date] , order: DESC }, filter: {categories: {nodes: {elemMatch: {id: {eq: $id}}}}}) {
        nodes {
            uri
            title
            slug
            date
            excerpt
            featuredImage{
                node{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }
            categories{
                nodes{
                    name
                    slug
                }
            }

            tags{
                nodes{
                    name
                }
            }
        }
    }

    otherCategories: allWpCategory(limit: 100, filter: {language: {code: {eq: $language}}}){
        nodes{
          name
          slug
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
